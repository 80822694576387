@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400;500;700&display=swap');

/* Common class starts */

html, body{font-family: "Roboto","Helvetica Neue",Helvetica,Arial,"Lucida Grande",sans-serif; height: 100%; font-weight: 300; font-size: 14px; color: #b3b3b3;}
body{padding-right: 0 !important;color: rgba(255,255,255,0.6); }

a{color: #80c3f3; opacity: 0.9 }
a:hover{color: #80c3f3; opacity: 1;}

fieldset{border-width: 1px !important; border-color:#8c8c8c !important;}
.text-r{text-align: right !important;}
.text-c{text-align: center !important;}
.text-l{text-align: left !important;}
.d-flex-r{display: flex; flex-wrap: wrap; flex-direction: row;}
.d-flex-v{display: flex; flex-wrap: wrap; flex-direction: column;}
.count-5 .custom-card{width: calc(20% - 15px); margin: 7.5px; }
.count-4 .custom-card{width: calc(25% - 15px); margin: 7.5px; }
.count-2 .chart{width: 50%;}
.count-2 .chart:first-child{padding-right: 10px;}
.count-2 .chart:last-child{padding-left: 10px;}
.no-alert{text-align: center !important; padding:15px 0 !important;}
input.editable-text:-moz-read-only{border:0; background:transparent;}
input.editable-text:read-only {border:0; background:transparent;}

.tube-row:hover input.editable-text:-moz-read-only {background:transparent;}
.tube-row:hover input.editable-text:read-only{background:transparent;}

[class*="sidebar-dark-"] .sidebar a{color: rgba(255,255,255,0.8);}
.nav-pills .nav-link{border-radius: 0;}
.wid-full{width: 100% !important; }
.wid-half{width: 50% !important; }
.model-med{max-width: 800px;}
.remove-meta{margin-top: 33px; display: inline-block;}
.main-footer{background-color: #171717;}
[class*="sidebar-dark-"]{background: #35322f; border-right: 1px solid #8c8c8c;}
.content-wrapper{background: #35322f; padding: 15px 0;}

.chart-heading{color: #8c8c8c; font-size: 12px; text-align: right; padding-right: 10px; font-weight: 700; text-transform: uppercase;  padding-bottom: 5px; }
.chart hr{border-top: 1px solid #8c8c8c; margin: 10px;}
.table-menu-btn{padding: 0;}
.table-menu-btn i{line-height: normal;}

.btn{margin: 4px 0; padding: 4px 15px; color: #FFF; border-radius: 0; border:1px solid transparent; text-transform: uppercase; font-weight: 300; font-size: 14px;}
.btn-default{background: #666666; opacity: 1; }
.btn-default:hover{background: #666666; opacity: 0.9; color: #b3b3b3;}
.btn-primary{background: #3a75a0; opacity: 1}
.btn-primary:hover{background: #3a75a0; opacity: 0.9}
.popover{background: #171717; border: 1px solid #8c8c8c; color: rgba(255,255,255,0.8);  }
.popover i{ color: rgba(255,255,255,0.8); font-size: 20px; cursor: pointer;  }
.bs-popover-auto[x-placement^="bottom"] > .arrow::after, .bs-popover-bottom > .arrow::after{border-bottom-color:#8c8c8c}
.btn:hover { color: rgba(255,255,255,0.8);text-decoration: none;}
.btn-icon {margin: 0px; padding:0px;}

.card-container-heading{color: rgba(255,255,255,0.7); font-size: 21px; font-weight: 300; margin-bottom: 5px; position: relative; min-height: 40px; line-height: 40px;}
.custom-table{width: 100%; color: rgba(255,255,255,0.7); margin-bottom: 15px;}
.custom-table tr:first-child td{padding-top: 15px;}
.custom-table td{text-align: left; padding: 4px 10px; font-size: 14px; text-transform: uppercase; font-weight: 700; vertical-align: top; line-height: normal;}
.custom-table td:first-child{width: 20px;}
.custom-table td:nth-child(2){padding: 5px;}
.custom-card-heading{color:#b3b2b2; font-weight: bold; padding: 10px; text-transform: uppercase; font-size: 14px;}

.card-container-body{display: flex; flex-wrap: wrap; flex-direction: row; justify-content: flex-start; align-content: flex-start; width: 100%;}
.custom-card-body{width: 100%;}
.viewall{padding: 10px 0; text-align: right; border-top: 1px solid #403e3c; margin: 0 10px 0px; text-transform: uppercase; font-size: 14px;}
.custom-card-big-heading{font-size: 68px; font-weight: 400; color: #8c8c8c; text-align: center}

.table-heading { display: inline-flex; align-items: center;flex: 1 0 auto; padding: 12px; vertical-align: middle; background: inherit; border-bottom: 1px solid #8c8c8c; width:100%; color: rgba(255,255,255,0.6); justify-content: space-between;}
.table-heading .text-right{cursor: pointer;}
.text-right .btn-icon{color:  rgba(255,255,255,0.8); line-height: 20px; font-size: 20px;}
.table-settings {font-size: 14px;padding: 5px 12px; font-weight: lighter;display: flex;  align-items: center;border-bottom: 1px solid #8c8c8c;background-color: #171717; width: 100%;box-sizing: border-box;justify-content: space-between;color: rgba(255,255,255,0.6); }
.table-settings .btn:hover{background: none; color:  rgba(255,255,255,0.6);  }
.table-title{font-size: 16px; color: rgba(255,255,255,0.6);}
.table-settings .dropdown-toggle:after{display: none;}
.btn-export{float: right; padding: 0; color: #80c3f3;}
.btn-export i{ font-size: 14px; margin-left: 5px;}
.dt-buttons{display: none}

.slider.slider-horizontal{width: 100%;}
.slider-handle{background-color: #3a75a0 !important;}
.slider-track{background: rgba(255,255,255,0.3) !important;}
.slider-teal .slider .slider-selection{background: rgba(255,255,255,0.4)}


.graph-container{border: 1px solid #8c8c8c; background:#171717; padding: 10px;}
.graph-container .modal-form{padding: 0;}

.sortable-icon{position: absolute; right:12px; top:15px; font-size: 16px; cursor: move; color: #b3b3b3;}
.sub-sortable-icon{position: absolute; right:12px; top:15px; font-size: 16px; cursor: move; color: #b3b3b3;}
.portlet-placeholder {border: 1px dashed #8c8c8c;margin: 0 1em 1em 0;height: 100px; width: 100%;}
.table-setting-icon{padding: 0 5px; cursor: pointer;}

.logo-loader{width:100%; min-height: 300px; background: url("./images/logo.png"); background-position: center center; display: inline-block; background-repeat: no-repeat; position: relative;}
.cam-loader{background: url("./images/logo.png"); background-position: center center; display: inline-block; background-repeat: no-repeat; position: relative;}
.collapsible{position: absolute; right: 40px; top: 15px; cursor: pointer; font-size: 15px; color: #b3b3b3;}
.card-tools .btn{margin:0; font-size: 20px; padding:0 8px !important;}
.card-tools .text-right{margin-top:4px;}
.btn-group.show .btn-tool, .btn-tool:hover{color: #b3b3b3;}
.card-tools a{opacity: 1;}
.graph-container-header{padding: 10px 10px 5px; text-align: right;}
.graph-container-header span{cursor: pointer; font-weight: 300;font-size: 14px; text-transform: uppercase;}
.graph-container-header span:hover{cursor: pointer; color: rgba(255,255,255,0.8)}
.graph-reload.selected, .graph-type.selected{font-weight: 400; color: #b3b3b3;}

.dashboard-main-card .dashboard-main-card{margin-left: -20px; margin-right: -20px; box-shadow: none; margin-bottom: 0;}
.dashboard-main-card .dashboard-main-card .dashboard-main-card > .card-header .card-title{font-size: 21px; color: rgba(255,255,255,0.6); line-height: 30px; }
.dashboard-main-card .dashboard-main-card.dashboard-card-border{ border:1px solid rgba(255,255,255,0.1); margin-right:-10px; margin-left:-10px; margin-bottom:10px;}

.alert-custom {
    color: rgba(255,255,255,0.6);
    background: #171717;
    border-color: rgba(255,255,255,0.6);
	text-align:center;
}

.container-overview {display: flex; width: 100%; }
.container-overview .custom-info-card-container{width:calc(50% - 15px); margin:0 7.5px;}
.container-overview .circle-graph-wrap:before{display: none;}
.container-overview .custom-card{height: 100%}
.apexcharts-zoom-icon, .apexcharts-pan-icon, .apexcharts-reset-icon, .apexcharts-menu-icon{display: none !important;}

/*.heatmap-plant-chart{width: calc(100% - 40px); background-image: url("/public/images/heatmap.jpg"); height: 436px; background-size: 100% 100%;}*/
.heatmap-plant-legend{position: absolute; right: 10px; top: 67px;}
.user-thumb{border-radius: 50%; width: 40px; height: 40px; display: block; margin: auto; }
a.fas{color: #b3b3b3; font-size: 15px;}
/*
.heatmap-plant-chart:after {
    content: "Plants";
    position: absolute;
    z-index: 1;
    transform: rotate(270deg);
    left: -7px;
    font-size: 12px;
    top: calc( 50% );
}

.heatmap-plant-chart:before {
    content: "Rows";
    position: absolute;
    z-index: 1;
    bottom: 10px;
    font-size: 12px;
    left: calc( 50% - 24px);
}
*/
.carousel-control-prev-icon, .carousel-control-next-icon{padding: 15px; background-color:  rgba(31,28,26,0.8); background-size: 50%; width: 48px; height:48px; border-radius: 50%; }
.carousel-control-next, .carousel-control-prev{min-width: 65px; width: 5%;}
.width-adjest{margin-left: 15px !important; margin-right: 15px !important;}
.dummy-input{border:1px solid #8c8c8c; padding: 0px 10px; height: 30px;  background:  #171717; box-sizing: border-box;width: 100%; margin: 0 4px 0 0; color: rgba(255,255,255,0.7); border-radius: 0; position: relative;}
.dummy-input label{margin-bottom: 0; line-height: 30px; }
.dummy-input-toggle .slider{top:37px; left: 10px; width: 95%;}
#photoPreview{margin-top: 10px;}
/* Common class ends */


/*camera body */
.camera-body img {display: block; width:auto; max-width:100%;}
.camera-body.cnt-4 img{max-height:220px;}
.camera-body.cnt-6 img{max-height:170px;}
.camera-body.cnt-8 img{max-height:125px;}
/*camera body ends */

/* Heatmap camera*/
.mgraph-container{margin-bottom: 10px;}
.camera-heatmap{padding: 10px 10px 10px 10px; width: 100%;}
.camera-heatmap{display: flex; }
/*.camera-heatmap ul{ display: flex; flex-wrap: wrap; list-style: none; padding: 0; margin: 0; align-items: center; }*/
.camera-heatmap ul{ display: flex; flex-wrap: wrap; list-style: none; padding: 0; margin: 0; align-items: center; width: 50%; position: relative }
.camera-heatmap ul:last-child:before{content: ""; position: absolute; height: calc(100% - 20px); width: 1px; background: #8c8c8c; display: inline-block; top: 25px; left: 0px;}
.count-2 .chart:last-child:before{content: ""; position: absolute; height: calc(100% - 20px); width: 1px; background: #8c8c8c; display: inline-block; top: 25px; left: 0px;}
.camera-heatmap ul:first-child{padding-right: 10px;}
.camera-heatmap ul:last-child{padding-left: 10px;}
/*.camera-heatmap ul li{width: 25%; padding: 6px; position:relative;}*/
.camera-heatmap ul li{width: 50%; padding: 6px; position:relative;}
.cam-refresh-global {position: absolute; z-index: 1; top: 5px; right: 5px; color: #80c3f3;}
.camera-heatmap li a{display: block;}
.camera-heatmap ul li img{width: 100%;  }
.axis-text{font-size: 14px; text-align: center; display: flex; align-items: center; justify-content: center;  text-transform: uppercase; white-space: nowrap; width:100% !important;}
.axis-text-h{font-size: 14px; text-align: center; display: flex; align-items: center; justify-content: center; padding: 10px 0; text-transform: uppercase; white-space: nowrap;}
/* Heatmap camera ends*/

/* User menu*/
.user-menu{font-weight: 300; border-radius: 0 !important;}
.user-menu a{font-weight: 300; font-size: 1rem !important; }
.user-menu .dropdown-menu{left: auto !important; right: 10px !important; width: 150px !important; border-radius: 0 !important;}
.user-menu .dropdown-menu li a{padding: 6px 12px; line-height: normal; display: block; color: rgba(255,255,255,0.6);}
.user-menu .dropdown-menu li a:hover{background-color: #35322f;}
.user-menu  img{display: inline-block; opacity: 1;}

/* Default inputs */

input{border:1px solid #8c8c8c; padding: 6px 10px; height: 30px;  background:  #171717; box-sizing: border-box;width: 100%; margin: 0 4px 0 0; color: rgba(255,255,255,0.7); border-radius: 0; font-size: 14px;}
select.form-control{border:1px solid #8c8c8c; padding: 4px 10px;  background:  #171717; box-sizing: border-box;width: 100%; margin: 0 4px 0 0; color: rgba(255,255,255,0.7); border-radius: 0; font-size: 14px;}
select.form-control option{border:1px solid #8c8c8c; padding: 4px 10px;  background:  #171717;color: rgba(255,255,255,0.7); }
select.form-control option:hover{border:1px solid #8c8c8c; padding: 4px 10px;  background:  #171717;color: rgba(255,255,255,0.7); }
.form-control:focus{border:1px solid #8c8c8c; padding: 4px 10px;  background:  #171717 !important;color: rgba(255,255,255,0.7);}
.select2-container--default .select2-selection--single{border:1px solid #8c8c8c; background:  #171717; box-sizing: border-box;width: 100%; margin: 0 4px 0 0; color: rgba(255,255,255,0.7); border-radius: 0; height: auto; padding: 0;}
.select2-container--default .select2-dropdown{background: #171717; border: 1px solid #8c8c8c !important; color: rgba(255,255,255,0.6);}
.select2-container--default .select2-results__option--highlighted[aria-selected]{background: #2c2825;}
.select2-container--default .select2-results__option--highlighted[aria-selected], .select2-container--default .select2-results__option--highlighted[aria-selected]:hover{ background: #35322f;}
.select2-search--dropdown{padding: 0; border-radius: 0;}
.select2-container--default .select2-results__option{font-size: 14px;}
.select2-search--dropdown .select2-search__field{border-radius: 0 ; background: #171717; color: #b3b3b3; border:1px solid #8c8c8c; height: 30px;}
.select2-container--default .select2-dropdown .select2-search__field:focus, .select2-container--default .select2-search--inline .select2-search__field:focus{border:1px solid #80c3f3; }
input[type=checkbox]{width: auto; height: auto;}
.form-control{border:1px solid #8c8c8c; padding: 6px 10px; height: 30px;  background:  #171717; box-sizing: border-box;width: 100%; margin: 0 4px 0 0; color: rgba(255,255,255,0.7); border-radius: 0; font-size: 14px;}
.select2-container--default.select2-container--disabled .select2-selection--single{background: rgba(255,255,255,0.05); }
.select2-container--default.select2-container--disabled .select2-selection--single span{color: rgba(255,255,255,0.3);}
/* Default inputs */

/* Checkbox css start */

/* The container */
.chk-box {
  display: block !important;
  position: relative;
  padding:3px 8px 0 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; line-height: 26px;
}

/* Hide the browser's default checkbox */
.chk-box input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 6px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.chk-box:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.chk-box input:checked ~ .checkmark {
  background-color: #35322f;

}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.chk-box input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.chk-box .checkmark:after {
  left: 7px;
  top: 2px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* checkbox css ends */
 /* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 15px;
  vertical-align: middle; margin-bottom: 0;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #2c2825;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 0px;
  bottom: 0px;
  background-color: #b2b1b0;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #37709b;
}

input:focus + .slider {
  box-shadow: 0 0 1px #37709b;
}

input:checked + .slider:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* The switch - the box around the slider  ends*/

/* Header reset */
.dashboard-logo {
  margin: 10px;
  content: " ";
  background: url(./images/logo.png) no-repeat;
  height: 40px !important;
  width: 93px;
  display: inline-block;
}
.navbar-custom { height: 60px;
  width: 100%;
  background-color: #171717;
  box-shadow: inset 0 -1px 0 0 #8c8c8c;
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 30202;
  border-bottom: none;padding: 0;}
.brand-text{color: #b3b3b3; font-weight: 300 !important; font-size: 24px; margin-left: 5px !important; visibility: visible !important; opacity: 1 !important;}
.brand-link{padding: 0px !important; margin:10px;}
.brand-link .brand-image{height: 40px; width: 85px; display: inline-block;}

.sidebar-mini.sidebar-collapse .main-header{ margin-left: 0 !important;}
:not(.layout-fixed) .main-sidebar{top: auto; min-height: calc(100% - 67px)}
.sidebar{padding: 0; height:100vh;}
.sidebar .nav-link{margin-top:15px; font-weight: 300; text-transform: uppercase; font-size: 14px;}
.navbar-custom .navbar-nav .nav-link{ line-height: 35px; min-width: 60px; font-size: 30px; color: #b3b3b3; padding: 0 1rem;}
.navbar-custom .navbar-nav .nav-link i{width: 1.6rem; text-align: center; display:inline-block; font-size: 28px;}
.navbar-custom .select2-container--default .select2-selection--single{background: #171717; color: #b3b3b3; border: 1px solid #8c8c8c; border-radius: 0; height: 30px; }
.navbar-custom .form-inline .input-group{min-width: 500px; background: #171717; border: 1px solid #8c8c8c; }
.navbar-custom .form-inline .input-group label{ }
.navbar-custom .form-inline .input-group label:before{content: ""; width: 1px; background: #8c8c8c; height: calc(100% - 12px);  left: -8px; top:6px; position: absolute; }
.select2-selection__arrow{top:2px !important;}
.navbar-custom .form-control-navbar{background: #171717; color: #b3b3b3; border: 1px solid #8c8c8c; border-radius: 0; height: 30px !important; margin: 0 0 0 10px; }
.navbar-custom .input-group-append{position: absolute; color:  #b3b3b3; right: 0;}
.navbar-custom .btn-navbar i{color: rgba(255,255,255,0.6); font-size: 18px; line-height: 28px;}
.select2-container--default .select2-selection--single .select2-selection__rendered{color: rgba(255,255,255,0.7); margin-top: 0; padding: 5px 10px; line-height: 18px; box-sizing: border-box; font-size: 14px;}
.navbar-custom .input-group.custom-search{min-width: 350px;}
.navbar-custom .input-group.custom-search input{font-size: 14px;}
.navbar-custom .input-group-sm select{background-color: #171717; border-radius: 0; height: 30px; border:1px solid #8c8c8c;}
.custom-header-select{border-radius: 0; top:1px;}
.custom-search .btn{margin: 0; padding: 1px 5px !important;}
.select2-container .select2-selection--multiple{background: #171717; color: rgba(255,255,255,0.6);border: 0px solid #8c8c8c; border-radius: 0; height: 30px; width: 100%; font-size: 14px; font-weight: 300;}
.select2-container--default .select2-selection--multiple .select2-selection__rendered .select2-search.select2-search--inline .select2-search__field{margin-top: 0;}
.ms-options-wrap.ms-has-selections > button{color:rgba(255,255,255,0.6); }
.select2-container--default.select2-container--focus .select2-selection--multiple{border:1px solid #80c3f3}
.select2-container--default .select2-selection--multiple .select2-selection__choice{border: 0px solid rgba(255,255,255,0.3); background: rgba(255,255,255,0.2); margin-top: 5px; border-radius: 0;}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover{color: #b3b3b3;}

/* Header reset  ends*/

/* Chart reset*/
.apexcharts-legend-series.apexcharts-no-click{cursor: pointer !important;}
.apexcharts-legend-text:hover{color: #b3b3b3 !important;}
/* Chart reset  ends*/


/* Custom Card */

.dashboard-main-card{background: rgba(44,40,37,1) !important;  border-radius: 0; }
.dashboard-main-card > .card-header .card-title{ color:rgba(255,255,255,1); font-size: 24px; font-weight: 300; }

.dashboard-main-card > .card-header .card-title span{font-size: 14px; font-weight: 300; display: inline-block; vertical-align: middle;}
.dashboard-main-card > .card-header {border-bottom: 0px solid #8c8c8c; cursor: pointer;}
.dashboard-main-card .btn{padding: 0 8px;}

.custom-card{ width: 100%; background: #171717; border:1px solid #8c8c8c; position: relative; margin: auto; height: auto; margin-bottom:10px;
-webkit-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.5);
-moz-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.5);
box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.5);
}
.custom-card .custom-card-header{ background: #2c2825; color: #b3b2b2; font-size: 18px; text-transform: uppercase; padding: 10px; border-bottom: 1px solid #8c8c8c; width: 100%; font-weight: 300;}
/*.custom-card .chart{flex:1;}*/
.custom-card .chart{width: 100%;}
.infopop{position: absolute; right: 10px; top: 10px; z-index: 1;}
.infopop i{color: #8c8c8c; font-size: 20px;}
.apexcharts-legend-marker{display: none !important;}
.dashboard-main-card .card-body{padding: 0 1.25rem;}
hr.separator{background: #8c8c8c;}
hr.separator-w{background: rgba(255,255,255,0.9);}
.b-spacer{display: block;padding: 20px;}
.card-header > .card-tools{display: flex;}
/* Custom Card  ends*/



/* Custom data tables starts */
#alertTable_info {display:none;}
.custom-datatable{ box-sizing: border-box !important;}
.custom-datatable .form-group{margin-bottom: 0;}
table.custom-datatable tbody tr{background: #171717; }
table.custom-datatable tbody tr:hover{background: #35322f; }
table.custom-datatable tbody td{font-size: 14px; font-weight: normal; color: rgba(255, 255, 255, 0.6); border-bottom: 1px solid #8c8c8c; padding:10px;}
table.custom-datatable thead th{font-size: 14px; font-weight: normal; color: rgba(255, 255, 255, 0.6);background: #2c2825; border-right: 1px solid #8c8c8c; padding:10px; padding-right:35px;}
table.custom-datatable thead th:last-child{ border-right: 0px solid #8c8c8c; }
table.dataTable.order-column tbody tr > .sorting_1, table.dataTable.order-column tbody tr > .sorting_2, table.dataTable.order-column tbody tr > .sorting_3, table.dataTable.display tbody tr > .sorting_1, table.dataTable.display tbody tr > .sorting_2, table.dataTable.display tbody tr > .sorting_3{background: #171717;}
table.dataTable.order-column tbody tr > .sorting_1, .cell-selected{background: rgb(120,30,43); background: linear-gradient(90deg, rgba(120,30,43,0.40) 0%, rgba(140,94,42,0.40) 100%); }
.DTFC_LeftHeadWrapper table, .DTFC_LeftBodyLiner table{border-right: 1px solid #8c8c8c;}
.DTFC_LeftHeadWrapper th{position: relative; z-index: 2;}
table tr:last-child td{border-bottom: 0;}

.sort-icon{position: absolute; right: 15px; top: 15px;}
.sort-icon-drag{position: absolute; right: -25px; top: 5px;}
.table-th-info{position: absolute; right: 35px; top: 15px;}
table.dataTable thead .sorting{background-image: none !important; position: relative;}
table.dataTable thead .sorting_desc{background-image: none !important; position: relative;}
table.dataTable thead .sorting_asc{background-image: none !important; position: relative;}
#reportTable thead th .fas{display: none;}
#batchTable thead th .fas{display: none;}


table.dataTable thead th, table.dataTable thead td{border-bottom: 1px solid #8c8c8c; position: relative;}

.DTCR_tableHeader :after, .DTCR_tableHeaderHover :after{
  position: absolute;
  /* content: url("/public/images/resize.png");  */
  display: inline-block;
  width: 16px;
  height: 10px;
  right: -23px;
  top:0px;
  z-index: 1;
}
th .fa-ellipsis-v{position: absolute; right: 15px; top: 20px; cursor: pointer;}

table.custom-datatable tbody td:nth-child(1){border-right: 0px solid #8c8c8c;}
table.custom-datatable tbody td:last-child{border-right: 0px solid #8c8c8c;}
.dataTables_scrollBody th div:after{position: relative !important; display: none !important;}
#alertTable th div i:before{position: relative !important; display: none !important;}
#propertyTable_wrapper th div i:before{position: relative !important; display: none !important;}
#harvestTable_wrapper th div i:before{position: relative !important; display: none !important;}

.table-border{border:1px solid #8c8c8c;}
.last-td{border-right: 1px solid #8c8c8c;}
table.dataTable.no-footer{border-bottom: 0px solid #8c8c8c;}
table.custom-datatable tbody td{position: relative;}
table.custom-datatable tbody td i.r-menu{position: absolute; right: 13px; top: 15px; cursor: pointer; padding: 0 5px;}


/* Custom data tables ends */

/* Custom  modal window starts */
.modal {z-index: 9999;}
.modal-content{background: #171717;  border: 1px solid #8c8c8c; border-radius: 0;}
.modal-header{color: rgba(255,255,255,0.6); height: 40px; padding:10px 10px 10px 15px; background: #413d39; border-bottom: 1px solid #8c8c8c; border-radius: 0;}
.modal-header .h4{font-size: 18px; font-weight: 300; line-height: 18px; text-transform: uppercase;}
.close{color: #b3b3b3; text-shadow: none;}
.close:hover, .mailbox-attachment-close:hover{color: #b3b3b3;}
.modal-body{color: rgba(255,255,255,0.6); font-size: 14px; font-weight:300;}
.modal-footer{border-top: 1px solid #8c8c8c;}
.modal-body{padding: 0;}
.modal-body h4{font-weight: 300; font-size: 14px; text-transform: uppercase; padding:15px; border-bottom: 1px solid #8c8c8c;}
.modal-form{padding: 15px;}
.modal-header .close, .modal-header .mailbox-attachment-close{padding: 12px 15px;}
.modal-content .modal-form{border:0;}
/* Custom  modal window ends*/

/* Datepicker calendar starts */
.daterangepicker{border: 1px solid #8c8c8c; background: #171717;  color: rgba(255,255,255,0.6);}
.daterangepicker .calendar-table{border: 1px solid #8c8c8c; background: #171717;  color: rgba(255,255,255,0.8);}
.daterangepicker td.available:hover, .daterangepicker th.available:hover{background: rgba(255,255,255,0.1)}
.daterangepicker td.off, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date, .daterangepicker td.off.end-date{background: #171717; color: rgba(255,255,255,0.3); }
.daterangepicker td.in-range{background: rgba(255,255,255,0.3); color: #b3b3b3;}
.daterangepicker td.active, .daterangepicker td.active:hover{background: #3a75a0; border-radius: 0;}
.daterangepicker .drp-calendar.left .calendar-table, .daterangepicker .drp-calendar.right .calendar-table{border:0;}
.daterangepicker .ranges li.active{background: #3a75a0;}
.daterangepicker .ranges li:hover{color:  #171717; background: rgba(255,255,255,0.6);}
.daterangepicker::after{border-bottom: 6px solid #8c8c8c;}
.daterangepicker{border-radius: 0;}
.daterangepicker .calendar-table th, .daterangepicker .calendar-table td{text-transform: uppercase;}
.daterangepicker .calendar-table .next span, .daterangepicker .calendar-table .prev span{border-color:#b3b3b3;}
.daterangepicker .drp-buttons .btn{font-weight: normal;}
/* Datepicker calendar ends*/

.custom-carousel {text-align: center; margin-bottom: 15px;}
.custom-carousel img{height: 350px; margin: auto; max-width: 100%;  }

/* Drop down menu */
.dropdown-menu{background: #171717;  border: 1px solid #8c8c8c; border-radius: 0; color:  rgba(255,255,255,0.6);}
.dropdown-item{color: rgba(255,255,255,0.6); font-weight: 400; font-size: 14px; margin-bottom: 0; padding: 5px 10px;}
.dropdown-item input{margin-right: 5px;}
.dropdown-item:focus, .dropdown-item:hover{ background: #35322f; color: rgba(255,255,255,0.6);}
label:not(.form-check-label):not(.custom-file-label){font-weight: 300;}
/* Drop down menu  ends */

/* Annotator starts  */
.annotator-wrap{display: flex; flex-wrap: wrap; flex-direction: row;  justify-content: space-between; width: 100%;}
.annotator-wrap .custom-card{width: calc(50% - 15px); margin: 7.5px ;}
.annotator-wrap .custom-card:last-child{width: calc(100% - 15px); }
.annotator-details-wrap{display: flex; width: 100%; flex-wrap: wrap; flex-direction: column;}
.menu-panel{display: block; width: 100%;}
.menu-panel .btn{margin: 0; min-height: 30px;}
.menu-panel .btn:hover{color: #b3b3b3;}
.image-box-panel{text-align: center;}
.menu-panel ul{list-style: none; padding: 0; margin: 0; display: flex; flex-wrap: wrap; flex-direction: row; width: 100%; align-items: flex-end; justify-content: flex-start;}
.menu-panel ul li{width: calc(25% - 15px); margin: 7.5px 0; position: relative; margin-right: 15px;}
.menu-panel ul li.wd-half{width: calc(12.5% - 15px); }
.menu-panel ul li.help {margin-left: auto;text-align: right;}
.help button:hover{color: #b3b3b3;}
.menu-panel ul li .btn{margin-right: 5px;}
.inline-switch{margin-right: 8px;}
.grid-panel{text-align: center;}
.grid-panel img{width: 100%;}
.modal-body p{padding: 10px;}
.menu-panel .slider-horizontal:before{background: transparent;}
.image-info-panel{display: flex; width:100%; flex-wrap: wrap; justify-content: space-between;}
.info-img{background: #171717;  border: 1px solid #8c8c8c; border-radius: 0; color:  rgba(255,255,255,0.6); margin: 15px 7.5px;  display: flex; flex-direction: column; width:calc(50% - 15px);
-webkit-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.5);
-moz-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.5);
box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.5);}
.info-img h3 {
background: #2c2825;
color:#b3b2b2;
font-size: 18px;
text-transform: uppercase;
padding: 10px;
border-bottom: 1px solid #8c8c8c;
 width: 100%;
font-weight: 300;
}
.info-img ul{margin: 10px; list-style: none; padding: 0; }
.info-img ul li{display: flex; flex-wrap: nowrap; }
.info-img ul li label{width: 200px;}
.head-form{display: inline-block; }
.head-form ul{list-style: none; padding: 0; margin: 0; display: flex; flex-wrap: wrap; flex-direction: row; width: 100%; }
.head-form ul li{font-size: 14px;  margin: 0 7.5px; position: relative; line-height: 32px;}
.head-form ul li .info-div{display: inline-block;}
.head-form ul li:first-child {margin-left: 0;}
.help button{padding: 0 !important; margin-right: 0 !important;}
.annotator-help {position:absolute !important;right:16px;}

/* Annotator ends  */

/* table like data */
.form-row-wrap{width: 100%; font-size: 14px;}
.form-row-wrap ul{list-style: none; display: flex; margin:0; padding: 0; width: 100%}
.form-row-wrap ul li{width: 20%; padding: 0 7.5px;}
.form-row-wrap ul li input{margin:0;}
.table-wise{display: flex; width: 100%; padding: 0; border:1px solid #8c8c8c; border-right: 0; border-left: 0;
  padding-left: 80px;
  overflow-y: visible; border: 1px solid #8c8c8c;

}
.input-oneline{display: flex; flex-wrap: nowrap;}
.input-oneline input{ width: 110px;margin:10px 0; }
.input-oneline span{width:1px; background: #8c8c8c; height: 50px; margin: 0 10px;}
.side-fix{width: 200px;}
.side-scroll{overflow-x: scroll; width: 100%; display: block;}
.side-scroll td{border-right: 1px solid #8c8c8c;}
.side-scroll tbody td{padding:0 10px !important;}
.side-scroll th{padding-right: 10px !important; border-bottom: 1px solid #8c8c8c; height: 51px;}
.headcol {
  position: absolute; text-align: center; line-height: 30px; font-size: 14px;
  width: 79px;
  left: 21px;
  top: auto;
  border-top-width: 0px;
  /*only relevant for first row*/
  margin-top: 1px;
  /*compensate for top border*/
  padding: 10px;
  border-right: 1px solid #8c8c8c;  border-bottom: 1px solid #8c8c8c; height: 51px; font-weight: normal;background: rgb(120,30,43); background: linear-gradient(90deg, rgba(120,30,43,0.40) 0%, rgba(140,94,42,0.40) 100%);
}
.side-scroll .table.custom-datatable thead th{}
.headcol .select2-selection{}
.custom-card-footer{width: 100%; padding:10px 0;}


/* Classifier starts  */
.classifier-wrap{display: flex; flex-wrap: nowrap; width: 100%; flex-direction: column;}
.classifier-top{display: flex; flex-wrap: nowrap; flex-direction: row; width: 100%;}
.classifier-top ul{display: flex; flex-wrap: nowrap; flex-direction: row; width: 100%; list-style: none; margin: 0; padding: 0; align-items: flex-end;}
.classifier-top ul li{width: 25%; max-width: 300px; padding: 0 7.5px;}
.classifier-top ul li .form-group{margin-bottom: 0;}
.classifier-top ul li .btn{margin: 0;}
.classifier-top ul li .btn-help:hover{color: #b3b3b3;}
.classifier-top ul li:last-child{ margin-left: auto; text-align: right;}
.classifier-top button{min-height: 30px;}
.classifier-img-grid{background: #171717;  border: 1px solid #8c8c8c; border-radius: 0; color:  rgba(255,255,255,0.6); margin: 15px 7.5px; padding: 15px; display: flex; flex-direction: row;
-webkit-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.5);
-moz-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.5);
box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.5);}
.left-img{width: 188px; margin-right: 15px;}
.left-img label{text-align: center; margin-bottom: 5px; width: 100%; margin-top: 15px;}
.left-img div:first-child label{margin-top:0;}
.right-img{flex:1;}
.right-img img{width: 100%;height: 100%; cursor: grab}
.left-img .grid-box{}
.grid-box ul{display: grid; padding: 0; margin: 0; list-style: none; grid-template-columns: repeat(auto-fill, minmax(56px, 1fr)); justify-items:stretch;}
.left-img .grid-box ul {width: 188px;padding-right: 20px;}
.ul-scroller{max-height: 224px; overflow-x: hidden; overflow-y:auto;  }

/* Classifier ends  */

.chart-legend-container {position:relative;}

.input-option-container {margin-right:70px;;width:300px; display:inline-block; vertical-align:middle;}

.context-menu {display:none;max-width:150px;}
.context-menu ul {list-style:none; margin:0px; padding:0px;}
.context-menu ul li{border-bottom: 1px dashed rgba(255, 255, 255, 0.3);}
.context-menu ul li:last-child{ border-bottom:0; }
.context-menu ul a {padding:5px 12px; display:block; font-size:14px;}
.context-menu ul a .fas {margin-right:10px;}

.annotator-control-prev {
	position: absolute;
    top: calc(50% - 18px);
    z-index: 10;
	left:15px;
    font-size: 36px;
}
.annotator-control-next {
	position: absolute;
    top: calc(50% - 18px);
    z-index: 10;
	right:15px;
    font-size: 36px;
}

.card-background {
	background: #302e2b;
    padding: 10px 10px 5px;
	margin:10px 0;
}

.tube-fieldset {position:relative;}
.tube-fieldset .remove {position: absolute; top: 15px; right: 5px;}
.tubes-container fieldset {margin: 15px 0px;}
legend {
	width: auto;
	font-size: 16px;
}

fieldset {
	display: block;
    margin-inline-start: 2px;
    margin-inline-end: 2px;
    padding-block-start: 0.35em;
    padding-inline-start: 0.75em;
    padding-inline-end: 0.75em;
    padding-block-end: 0.625em;
    min-inline-size: min-content;
    border-width: 2px;
    border-style: groove;
    border-color: threedface;
    border-image: initial;
}

.has-error input {border-color:#FF0000 !important;}
.nav-sidebar>.nav-item .nav-icon {display:inline-block;}

.two-column{display: flex; width: 100%; flex-wrap: wrap; align-items: center; align-items: top; padding:10px;}
.line-vertical{width: 1px; height: calc(100% - 20px); background: #8c8c8c; margin:25px 10px 0px;}
.two-column .flexdiv{flex:1;}
.two-column .flexdiv ul{list-style: none; width: 100%; padding: 0;}
.two-column .flexdiv ul li {width: 50%; padding: 6px; position: relative; float: left;}
.two-column .flexdiv ul li img {width:100%;}
.form-control:disabled, .form-control[readonly] {background-color:rgba(255,255,255,0.05)}

.carousel-item-padding-7-px {padding:7.5px}

div.option-group {display: inline-block; border:1px solid; margin:2px; margin-right:10px; float: right;}
div.option-group .btn { padding: 3px 8px; margin:0px; }

.sidebar-collapse aside.main-sidebar.sticky-sidebar {position: fixed;top:0; min-height: 100%;}

aside.sidebar-dark-primary.main-sidebar{
  width: 60px;
  height: 100%;
  color: #b3b3b3;
  position: fixed !important;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 30201;
  background-color: #35322f;
  transition: width .2s,background-color .2s;
  border-right: 1px solid #8c8c8c;
  box-shadow: 1px 0px 4px -1px rgb(0 0 0 / 75%) !important;
  top: 60px;
}

@media (max-width:400px) {
	.heatmap-plant-legend.disease {top: 91px;}
}
@media (max-width:516px) {
	.input-option-container {width:185px;}
}


@media (min-width:992px) {
.sidebar-mini.sidebar-collapse .main-sidebar, .sidebar-mini.sidebar-collapse .main-sidebar::before{width: 60px;}
.sidebar-mini.sidebar-collapse .content-wrapper, .sidebar-mini.sidebar-collapse .main-footer{ margin-left: 60px !important;}
}
@media (min-width:601px) {
.fruit-wrapper-chart{padding-right: 0px; position: relative; width: 100%;}
.heatmap-plant-chart, .chart{width: 100%; display: block;}
/*.circle-graph-wrap:before{content: ""; position: absolute; height: calc(100% - 50px); width: 1px; background: #8c8c8c; display: inline-block; top: 25px; left: 0px;}*/
.wid-half .circle-graph-wrap:before{content: ""; position: absolute; height: calc(100% - 50px); width: 0px; background: #8c8c8c; display: inline-block; top: 25px; left: 0px;}
.pest-wrapper-chart{padding-left: 0px; position: relative;}
.disease-wrapper-chart{position: relative; height: 90%; text-align: center; }
.disease-wrapper-chart .no-data{display: flex; align-items: center; justify-content: center; height: 100%;}
.pest-wrapper-chart:before{content: ""; position: absolute; height: calc(100% - 50px); width: 1px; background: #8c8c8c; display: inline-block; top: 25px; left: 0px;}
.disease-wrapper-chart:before{content: ""; position: absolute; height: calc(100% - 50px); width: 1px; background: #8c8c8c; display: inline-block; top: 25px; left: 0px;}
}
@media (max-width:1024px) {
    .brand-text{display: none;}
    .count-5 .custom-card{width: calc(33.33% - 15px)}
    .navbar-custom .input-group.custom-search{min-width: 300px;}
    .navbar-custom .form-inline .input-group{min-width: 250px;}

}
@media (max-width:992px) {
    .count-5 .custom-card{width: calc(50% - 15px)}
}
@media (max-width:600px) {
    .count-5 .custom-card{width: calc(100% - 15px)}
    .d-flex-r{flex-direction: column;}
    .content-wrapper{padding: 7.5px 0;}
    .content-wrapper > .content{padding: 0;}
    .custom-search{display: none;}
    .card-header{padding: .75rem;}
    .dashboard-main-card .card-body{padding: 0px;}
    .dashboard-main-card .dashboard-main-card {margin-left: 0px; margin-right: 0px;}
}
@media (max-width:400px)   {
    .navbar-custom .form-inline .input-group{ min-width:210px;  }
}

div.area-menu a.active {color: #80c3f3}
div.area-menu div.active a{color: #80c3f3}
div.highlight-img .custom-card {border:1px solid #ffff00}

.btn-arrow-left {font-size: 20px;}
.modal-backdrop {z-index: 99999;}
.modal {z-index: 99999;}

/* one selector per rule as explained here : http://www.sitepoint.com/html5-full-screen-api/ */
.leaflet-container:-webkit-full-screen { width: 100% !important; height: 100% !important; z-index: 99999; }
.leaflet-container:-ms-fullscreen { width: 100% !important; height: 100% !important; z-index: 99999; }
.leaflet-container:full-screen { width: 100% !important; height: 100% !important; z-index: 99999; }
.leaflet-container:fullscreen { width: 100% !important; height: 100% !important; z-index: 99999; }
.leaflet-pseudo-fullscreen { position: fixed !important; width: 100% !important; height: 100% !important; top: 0px !important; left: 0px !important; z-index: 99999; }

.dropdown-menu.show { z-index: 9999; }

.info-box {background: #171717; border: 1px solid #8c8c8c;}

.nav-pills.card-header-pills .nav-link:hover{color: rgba(255, 255, 255, 0.6)}
.nav-pills.card-header-pills .nav-link { border-radius: 0; border: solid 1px #8c8c8c; border-left: none; margin-top: -1px; margin-bottom: -1px; opacity: 1;}
.nav-pills.card-header-pills .nav-link.active {border-bottom:0px; color: #80c3f3;background: #171717 !important;border-top: 2px solid #80c3f3; opacity: 1;}
.card-header .nav-link.active {border-bottom-color: #171717 !important; opacity: 1;}

.grazzing-plan-table tr:nth-child(2) .sort-icon{
  display: none;
}

.animation-spin {
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}

.sidebar-mini.sidebar-collapse .main-sidebar.sidebar-focused, .sidebar-mini.sidebar-collapse .main-sidebar:hover {
  width: 300px;
  border-bottom: 1px solid #8c8c8c;
  background-color: #161311;
  z-index: 30201;
}

.nav-sidebar>.primary-nav-item .nav-icon {height: 48px;width: 60px; font-size: 28px;     text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;}
.primary-nav-item .nav-link {margin:0px; padding:0px; display: flex}

.nav-icon-label {font-size: 1.17em; 
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  margin: 0;
  transition: margin .5s; font-weight: normal; text-transform: none;}

.middle-section {
    flex: 1;
    display: flex;
    position: relative;
    margin-top: 60px;
}

#root.wrapper  {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 100vh;
}
