.content-wrapper {
    padding: 0px;
}
.page-title-bar .title-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    box-sizing: border-box;
    padding: 4px;
}
nav.breadcrumbs {
    padding: 14px 0 9px 0;
    display: flex;
    align-items: center;
}
pd-tooltip.pd-tooltip--ellipsed {
    cursor: default;
}

.flyout-parent.flyout-parent--ellipsed {
    padding: 0;
    margin: 0;
}
.pd-tooltip {
    padding: 0 10px;
    margin: 0 -5px;
    display: inline-flex;
    cursor: pointer;
}
.flyout-parent {
    position: relative;
}
.flyout-parent.flyout-parent--ellipsed .flyout-control {
    width: 100%;
}
.flyout-parent .flyout-control {
    display: flex;
    align-items: center;
}

nav.breadcrumbs > a,
nav.breadcrumbs span {
    font-size: 18px;
    max-width: 500px;
}
.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: bottom;
}
.pd-tooltip.pd-tooltip--ellipsed {
    cursor: default;
}
.page-title-bar .title-bar .right-controls {
    display: flex;
    align-items: center;
    padding-left: 30px;
}
.page-title-bar .title-bar .toggle-links {
    display: flex;
    align-items: center;
    font-size: 16px;
}
.f-16 {
    font-size: 16px !important;
}

.pd-data-table-v7 {
    position: relative;
    border: 1px solid #8c8c8c;
    scrollbar-width: thin;
    scrollbar-color: #413d39 #171717;
}

.pd-data-table-v7 .pd-data-table-title {
    font-size: 16px;
    text-transform: uppercase;
    background-color: #272522;
    display: flex;
    white-space: nowrap;
    align-items: center;
}

.pd-data-table-v7 .pd-data-table-title .pd-data-table-title-text {
    padding: 12px;
    box-sizing: border-box;
    flex: 1 0 auto;
}

.search-text {
    width: 300px;
}

.form-control {
    background-color: #171717;
    color: #b3b3b3;
    font-weight: 300;
    border: 1px solid #8c8c8c;
}

.pd-data-table-v7 .pd-data-table-subtitle {
    font-size: 18px;
    padding: 10px 12px;
    font-weight: lighter;
    display: flex;
    align-items: center;
    background-color: #171717;
    border-bottom: 1px solid #8c8c8c;
    width: 100%;
    box-sizing: border-box;
}

.pd-data-table-v7 .pd-data-table-subtitle .pd-data-table-subtitle-text {
    line-height: 1.3;
}

.pd-data-table-v7 .pd-data-table-subtitle .pd-data-table-toolbar {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.pd-data-table-v7
    .pd-data-table-subtitle
    .pd-data-table-toolbar
    .pd-data-table-actions {
    display: flex;
    align-items: center;
    padding: 4px 10px 4px;
    border-right: 1px solid #8c8c8c;
}

.pd-data-table-v7
    .pd-data-table-subtitle
    .pd-data-table-toolbar
    .pd-data-table-controls {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.pd-button-icon {
    font-size: 16px;
    text-transform: none;
    color: #b3b3b3;
}

.pd-data-table-v7 .pd-data-table-content {
    overflow: auto;
    background-color: #171717;
}

.headers-row {
    display: flex;
    flex: 1 0 auto;
    min-width: 360px;
}

.pd-data-table-v7 .pd-data-table-head .pd-data-table-header-cell {
    padding: 0;
    position: relative;
    border-bottom: none;
    justify-content: flex-start;
    background: #272522;
    outline: none;
}

.pd-data-table-v7 .pd-data-table-cell {
    align-items: center;
    padding: 12px;
    vertical-align: middle;
    background: #272522;
}

.pd-data-table-v7 .pd-data-table-head {
    display: flex;
    flex-direction: column;
}

.pd-data-table-v7 .pd-data-table-head .pd-data-table-header-cell > * {
    box-sizing: border-box;
    height: 100%;
}

.pd-data-table-v7 .pd-data-table-head .headers-row div[role="columnheader"] {
    border-bottom: 1px solid #8c8c8c;
    border-left: 1px solid #8c8c8c;
}

.pd-data-table-v7
    .pd-data-table-head
    .headers-row
    div[role="columnheader"]:first-child {
    border-left: none;
}

.pd-data-table-v7 .pd-data-table-head .resize-handle {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: -12px;
    justify-content: space-evenly;
    height: 100%;
    width: 15px;
    margin: 0 5px;
    z-index: 2;
    padding: 0;
}

.pd-data-table-v7 .pd-data-table-head .resize-handle .left {
    justify-content: flex-end;
    padding-right: 2px;
}

.pd-data-table-v7 .pd-data-table-head .resize-handle .left,
.pd-data-table-v7 .pd-data-table-head .resize-handle .right {
    width: 8px;
    pointer-events: none;
}

.pd-data-table-v7 .pd-data-table-head .resize-handle .svg-icon-button {
    width: 10px;
    height: 10px;
}

.pd-data-table-v7 .pd-data-table-head .resize-handle .right {
    justify-content: flex-start;
    padding-left: 1px;
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: bottom;
}

.pd-data-table-v7
    .pd-data-table-head
    .pd-data-table-header-cell
    .pd-data-table-header-cell-contents
    .main-header-cell {
    justify-content: center;
}

.flyout-parent.flyout-parent--ellipsed .flyout-control {
    width: 100%;
}
.flyout-parent .flyout-control {
    display: flex;
    align-items: center;
}

.pd-data-table-v7
    .pd-data-table-head
    .pd-data-table-header-cell
    .pd-data-table-header-cell-contents.--lowercase {
    text-transform: none;
}
.pd-data-table-v7
    .has-subcolumns
    .pd-data-table-head
    .pd-data-table-header-cell
    .pd-data-table-header-cell-contents {
    padding: 8px 12px 6px;
}
.pd-data-table-v7
    .pd-data-table-head
    .pd-data-table-header-cell
    .pd-data-table-header-cell-contents {
    display: flex;
    align-items: center;
    padding: 12px;
    width: 100%;
    box-sizing: border-box;
    text-transform: capitalize;
}
.pd-tooltip.pd-tooltip--ellipsed {
    cursor: default;
}
.flyout-parent.flyout-parent--ellipsed {
    padding: 0;
    margin: 0;
}
.pd-tooltip {
    padding: 0 10px;
    margin: 0 -5px;
    display: inline-flex;
    cursor: pointer;
}
.flyout-parent {
    position: relative;
}

.pd-data-table-v7
    .pd-data-table-head
    .headers-row
    div[role="columnheader"]
    > button {
    padding: 0;
    border: none;
    width: 100%;
    background: transparent;
    font-weight: 300;
    font-size: 14px;
    color: #b3b3b3;
}

.pd-data-table-v7
    .pd-data-table-head
    .pd-data-table-header-cell.has-tooltip
    .pd-data-table-header-cell-contents {
    padding-right: 50px;
}

.pd-data-table-v7
    .pd-data-table-head
    .pd-data-table-header-cell.--sortable
    .pd-data-table-header-cell-contents {
    padding-right: 30px;
    cursor: pointer;
}

.pd-data-table-v7 .pd-data-table-head .headers-row .header-sort-arrow {
    display: flex;
    margin-left: 3px;
    position: absolute;
    top: 0;
    right: 10px;
    height: 100%;
    align-items: center;
}

.pd-data-table-v7
    .pd-data-table-head
    .pd-data-table-header-cell.--sort-active.-sort-asc {
    box-shadow: inset 0 3px 0 0 #67a0c8;
}

.pd-data-table-v7
    .pd-data-table-head
    .pd-data-table-header-cell.--sort-active.-sort-desc {
    box-shadow: inset 0 -3px 0 0 #67a0c8;
}

.pd-data-table-v7 .pd-data-table-head .pd-data-table-header-cell.--sort-active {
    background: #171717;
}

.pd-data-table-v7 .pd-data-table-body .pd-data-table-row:first-child {
    border-top: 0;
}

.pd-data-table-v7 .pd-data-table-body .pd-data-table-row {
    width: 100%;
    background-color: #171717;
}
.pd-data-table-v7 .pd-data-table-row {
    white-space: nowrap;
}

.pd-data-table-v7 .pd-data-table-body .pd-data-table-cell-wrapper {
    text-overflow: unset;
    border-bottom: 1px solid #8c8c8c;
}

.pd-data-table-v7 .pd-data-table-body .pd-data-table-cell.--sort-active {
    background: #141211;
}

.pd-data-table-v7 .pd-data-table-body .pd-data-table-cell {
    font-size: 13px;
    line-height: 16px;
    height: 100%;
    box-sizing: border-box;
    position: relative;
    display: flex;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.pd-data-table-v7 .pd-data-table-cell.pd-data-table-cell--bold {
    font-weight: 700;
}
.pd-data-table-v7 .pd-data-table-cell {
    align-items: center;
    padding: 12px;
    vertical-align: middle;
    background: #171717;
}

.pd-data-table-v7
    .pd-data-table-head
    .headers-row
    div[role="columnheader"]:last-child
    .resize-handle {
    margin-right: 12px;
}

.pd-data-table-v7
    .pd-data-table-head
    .headers-row
    div[role="columnheader"]:last-child
    .resize-handle
    .right {
    display: none;
}

.pd-data-table-v7
    .pd-data-table-head
    .pd-data-table-header-cell:hover
    .header-sort-arrow.is-not-active {
    opacity: 1;
}

.pd-data-table-v7
    .pd-data-table-head
    .pd-data-table-header-cell
    .header-sort-arrow.is-not-active {
    opacity: 0.25;
}

.pd-data-table-v7
    .pd-data-table-head
    .pd-data-table-header-cell.has-tooltip
    .metric-tooltip {
    position: absolute;
    top: 0;
    right: 30px;
    height: 100%;
}

.pd-data-table-v7
    .pd-data-table-body
    .pd-data-table-cell-wrapper
    .number-column,
.pd-data-table-v7
    .pd-data-table-body
    .pd-data-table-cell-wrapper
    .number-cost-column,
.pd-data-table-v7 .pd-data-table-body .pd-data-table-cell-wrapper .cost-column,
.pd-data-table-v7 .pd-data-table-body .pd-data-table-cell-wrapper .id-column {
    text-align: right;
    font-family: "Roboto Mono", sans-serif;
}

.pd-data-table-v7
    .pd-data-table-body
    .pd-data-table-cell-wrapper
    .number-column,
.pd-data-table-v7
    .pd-data-table-body
    .pd-data-table-cell-wrapper
    .number-column
    .flyout-control,
.pd-data-table-v7
    .pd-data-table-body
    .pd-data-table-cell-wrapper
    .number-cost-column,
.pd-data-table-v7
    .pd-data-table-body
    .pd-data-table-cell-wrapper
    .number-cost-column
    .flyout-control,
.pd-data-table-v7 .pd-data-table-body .pd-data-table-cell-wrapper .cost-column,
.pd-data-table-v7
    .pd-data-table-body
    .pd-data-table-cell-wrapper
    .cost-column
    .flyout-control,
.pd-data-table-v7 .pd-data-table-body .pd-data-table-cell-wrapper .id-column,
.pd-data-table-v7
    .pd-data-table-body
    .pd-data-table-cell-wrapper
    .id-column
    .flyout-control {
    justify-content: flex-end;
}

.pd-data-table-v7
    .pd-data-table-body
    .pd-data-table-row:not(.disabled):hover
    .pd-data-table-cell:not([class*=" outlier-"]),
.pd-data-table-v7
    .pd-data-table-body
    .pd-data-table-row:not(.disabled).hover
    .pd-data-table-cell:not([class*=" outlier-"]) {
    background: #35322f;
}

.pd-data-table-v7
    .pd-data-table-body
    .pd-data-table-row:not(.disabled):hover
    .pd-data-table-cell,
.pd-data-table-v7
    .pd-data-table-body
    .pd-data-table-row:not(.disabled).hover
    .pd-data-table-cell {
    color: #fff;
}

.pd-data-table-v7
    .pd-data-table-body
    .pd-data-table-row:not(.disabled):hover
    .pd-data-table-cell:not([class*=" outlier-"]).--sort-active,
.pd-data-table-v7
    .pd-data-table-body
    .pd-data-table-row:not(.disabled).hover
    .pd-data-table-cell:not([class*=" outlier-"]).--sort-active {
    background: #292623;
}

.pd-data-table-v7
    .pd-data-table-body
    .pd-data-table-row:not(.disabled):hover
    .pd-data-table-cell:not([class*=" outlier-"]),
.pd-data-table-v7
    .pd-data-table-body
    .pd-data-table-row:not(.disabled).hover
    .pd-data-table-cell:not([class*=" outlier-"]) {
    background: #35322f;
}

.page-banner {
    width: 100%;
    position: relative;
    padding-left: 4px;
    box-sizing: border-box;
}

.page-banner .page-banner-messages {
    overflow: hidden;
    border-bottom: 1px solid #8c8c8c;
    border-left: 1px solid #8c8c8c;
}

.page-banner .page-banner-messages > :last-child {
    margin-bottom: 0;
}

.pd-banner.pd-banner-warn {
    background-color: #88761c;
}
.pd-banner {
    box-sizing: border-box;
    padding: 3px 20px;
    width: 100%;
    margin-bottom: 4px;
    color: #fff;
}

.pd-banner .pd-banner-content {
    display: flex;
    align-items: center;
}

.pd-banner .pd-banner-content .pd-banner-icon {
    display: flex;
}

.pd-banner .pd-banner-content .pd-banner-children {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
}

.pd-banner .pd-banner-close,
.pd-banner .pd-button-icon {
    display: flex;
    margin-left: auto;
    color: #ccc;
    cursor: pointer;
}

.pd-banner .pd-banner-content i {
    font-size: 20px;
    margin-right: 5px;
    color: #fff;
}

.page-banner .page-banner-messages .pd-banner-children a {
    margin-right: 2px;
    color: #fff;
    text-decoration: underline;
    font-weight: 300;
}

.pd-banner .pd-banner-content a {
    font-weight: 700;
}

nav.breadcrumbs > i {
    font-size: 12px;
}

.drop-shadow {
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 75%);
}
.pd-tab-link-bar {
    margin-bottom: 15px;
    background-color: #272522;
    border: 1px solid #8c8c8c;
    display: flex;
}

.pd-tab-link-bar a.tab-link.active {
    color: #67a0c8;
    background-color: #171717;
    box-shadow: inset 0 2px 0 0 #80c3f3;
}
.pd-tab-link-bar a.tab-link {
    color: #b3b3b3;
    border: none;
    border-right: 1px solid #8c8c8c;
    font-size: 16px;
    padding: 8px 15px;
    background-color: #26221e;
}

.pd-data-table-v7
    .pd-data-table-body
    .pd-data-table-cell
    .expand-arrow.expanded {
    transform: rotate(-0.5turn);
}

.pd-data-table-v7 .pd-data-table-body .pd-data-table-cell .expand-arrow {
    height: 24px;
    cursor: pointer;
    transition: transform 0.25s;
    width: 24px;
    text-align: center;
    line-height: 24px;
}

.pd-data-table-v7
    .pd-data-table-body
    .pd-data-table-row.pd-data-table-row--indented {
    border-left: 3px solid #67a0c8;
}

.mw-200 {
    min-width: 200px;
}

.modal-dialog .modal-button-group {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.control-row.column-controls-header.header--no-subcolumns,
.column-controls-header.column-controls-header.header--no-subcolumns,
.subcolumn-selector-header.column-controls-header.header--no-subcolumns {
    margin-bottom: 10px;
}
.control-row.column-controls-header,
.column-controls-header.column-controls-header,
.subcolumn-selector-header.column-controls-header {
    border-bottom: none;
    border-top: 1px solid #8c8c8c;
    font-size: 16px;
    padding: 14px 0 0 0;
}
.control-row,
.column-controls-header,
.subcolumn-selector-header {
    display: grid;
    grid-template-columns: 14px 25% repeat(auto-fill, minmax(75px, 1fr));
    align-items: center;
    border-bottom: 1px solid #8c8c8c;
    padding: 0 6px;
    height: 40px;
}

.column-name-header {
    grid-column-start: span 2;
}
.subcolumn-header {
    grid-column-start: span 6;
}

.pd-checkbox-container {
    max-width: 110px;
    font-size: 14px;
    font-weight: 300;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: bottom;
}

input[type="checkbox"] {
    width: 1em;
    height: 1em;
}

.subcolumn-selector-header {
    display: grid;
    grid-template-columns: 14px 25% repeat(auto-fill, minmax(75px, 1fr));
    align-items: center;
    border-bottom: 1px solid #8c8c8c;
    padding: 0 6px;
}

.column-controls-container .form-check {
    margin: 8px;
}

.column-controls-container .form-label {
    line-height: 40px;
    padding-right: 0px;
}

.movable-columns {
    border: 1px solid #8c8c8c;
    border-top: none;
    border-bottom: none;
}

.column-controls-draggable-row:hover {
    color: #fff;
    background-color: #413d39;
}

.modal-backdrop {
    opacity: 1;
    background-color: rgba(51, 51, 51, 0.67);
}

.plot-container .chart-main {
    background-color: #171717;
    border: 1px solid #8c8c8c;
}

.plot-container .chart-main .chart-title {
    background-color: #272522;
    border-bottom: 1px solid #8c8c8c;
    font-size: 18px;
    white-space: normal;
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 40px;
    box-sizing: border-box;
    margin-bottom: 15px;
}

.plot-container .chart-main .chart-title .chart-title-content {
    position: relative;
    display: flex;
    align-items: center;
    max-width: calc(100% - 150px);
    z-index: 2;
    padding: 8px 10px;
    box-sizing: border-box;
}

.plot-container .chart-main .chart-title .chart-controls {
    border-left: 1px solid #8c8c8c;
    display: flex;
}

.chart-controls {
    border-left: 1px solid #8c8c8c;
    margin-left: 10px;
    padding: 0 10px;
    display: flex;
    align-items: center;
}

.chartActionsContainer .chartStyleSelectionContainer {
    margin-top: -1px;
    display: flex;
    align-items: center;
}

.chartActionsContainer .chartStyleSelectionContainer label {
    font-weight: 400;
    white-space: nowrap;
}

.chartActionsContainer label {
    margin-right: 13px;
    margin-bottom: 0px;
}

.plot-container .chart-main .chart-body {
    padding-top: 15px;
}

.pd-button-selected {
    background: #171717;
}

.tooltipContent {
    font-size: 14px;
    color: rgba(255,255,255,.85);
    display: block;
    background-color: #161311;
    padding: 0.4em 0.6em;
    border: 1px solid #8c8c8c;
    line-height: 1.3em;
    white-space: nowrap;
    min-width: 160px;
    overflow: hidden;
}